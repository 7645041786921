.titleRecommended {
  font-weight: 600;
  font-size: 1.275em;
  color: #005F6B;
  margin-bottom: 10px;
}
//max-width tudoq ue estiver a abaixo desse estilo vai ter alterações
//min-width tudo que estiver acima do px informado


.responsive-card {
  height: 53rem !important;
}

@media (min-width: 500px)  {
  .responsive-card {
    height: 43rem !important;
  }
}

.responsive-card-continue {
  background-position: right top !important;
  background-Size: 20% auto !important;
  height: 25% !important;
}

@media (max-width: 1024px)  {
  .responsive-card-continue {
    background-position: right top !important;
    background-Size: 20% auto !important;
    height: auto !important;
  }
}

.responsive-card-courses {
  height: 66%!important;
}

@media (max-width: 1024px)  {
  .responsive-card-courses {
    height: auto!important;
  }
}

.responsive-skeleton {
  width: 80% !important;
  height: 70px !important;
  margin-top: -7% !important;
}

@media (min-width: 320px)  {
  .responsive-skeleton {
    width: 82% !important;
    height: 70px !important;
    margin-top: -4% !important;
  }
  .mobile {
    display: block !important;
  }
}

@media (min-width: 1024px) {
  .responsive-skeleton {
    width: 91% !important;
    height: 70px !important;
    margin-top: -2% !important;
  }
  .mobile {
    display: block !important;
  }
}

@media (min-width: 1100px) {
  .responsive-skeleton {
    width: 77% !important;
    height: 70px !important;
    margin-top: -6% !important;
  }
}

@media (min-width: 1400px) {
  .responsive-skeleton {
    width: 80% !important;
    height: 70px !important;
    margin-top: -6% !important;
  }
}

.mobile {
  display: block !important;
}

@media (max-width: 476px)  {
  .mobile {
    display: none !important;
  }
}


