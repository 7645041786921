.InputContainer {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #666360;

  & + div {
    margin-top: 8px;
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;
    color: #000;
    height: 40px;

    &::placeholder {
      color: #666360;
    }

    &:focus {
      outline: none;
    }
  }
}

.InputContainerAdminActive {
  border: 2px solid #802A00 !important;
  outline: none !important;
}

.InputContainerAdminError {
  border: 2px solid #f53030;

  svg {
    color: #f53030 !important;
  }
}

.iconAdminActive {
  color: #802A00 !important;
}
