.chooseFile {
  .inputFile {
    display: none;
  }

  label {
    display: flex;
    gap: 10px;
    margin-top: 4px;
    margin-left: 5px;
    align-items: center;

    color: #005f6b;
    font-size: 1rem;
    cursor: pointer;

    &:hover {
      color: #008c9e;
    }
  }
}
