.MuiTableContainer-root {
  height: 90%;
}

.trail-header {
  align-items: center;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 2rem;

  strong {
    font: 500 2.5rem Poppins, sans-serif !important;
    color: #6f95ff;
    line-height: 2rem;
  }
}

.trail-table-card {
  background-color: #fff;
  width: 100%;
  height: 100%;
  padding: 20px;
  border-radius: 6px;
}

.trail-table-card-header {
  display: flex;
  padding: 0 10px;
  justify-content: space-between;
  align-items: baseline;
}

.trail-table {
  width: 100%;
  position: relative;
}

.trail-table-head {
  background-color: #fff;
  color: #6f95ff !important;
}

.trail-table-head-font {
  color: rgb(70, 70, 78) !important;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: Poppins, sans-serif;
}

.trail-table-svg:hover {
  color: #6f95ff;
}

.trail-table-body-font {
  font-size: 15px !important;
  font-family: Poppins, sans-serif !important;
  color: #80808f !important;
}

.cluster-table-container {
  margin-top: 30px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  .cluster-header {
    align-items: center;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 2rem;

    strong {
      font: 500 2.5rem Poppins, sans-serif;
      color: #668036;
      line-height: 2rem;
    }
  }

  .cluster-table-card {
    background-color: #fff;
    width: 100%;
    height: 100%;
    padding: 20px;
    border-radius: 6px;
  }

  .cluster-table-card-header {
    display: flex;
    padding: 0 10px;
    justify-content: space-between;
    align-items: baseline;
  }

  .cluster-table {
    width: 100%;
    position: relative;
  }

  .cluster-table-head {
    background-color: #fff;
    color: #668036;
  }

  .cluster-table-head-font {
    color: rgb(70, 70, 78);
    font-size: 16px;
    font-weight: 600;
    font-family: Poppins, sans-serif;
  }

  .cluster-table-svg:hover {
    color: #668036;
  }

  .cluster-table-body-font {
    font-size: 15px;
    font-family: Poppins, sans-serif;
    color: #80808f;
    background-color: #fff;
  }
}


@media (max-width: 544px) {
  .trail-table-svg {
    width: 12px;
    height: 20px;
  }
  .cluster-table-svg {
    width: 12px;
    height: 20px;
  }
}
