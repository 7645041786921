.card-alert {
  display: flex;
  width: 100%;
  background-color: #cacfd1;
  border-radius: 3px;
  padding: 16px 16px 16px 12px;
}

.container-card {
  display: flex
}

.card-tutorials{
  display: block;
  max-width: 280px;
  text-decoration: none;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px, rgb(15 15 15 / 10%) 0px 2px 4px;
  border-radius: 3px;
  height: 100%;
  cursor: pointer;
  margin: 4px;
}

.image {
  max-width: 280px;
  object-fit: cover;
}

@media only screen and (max-width: 768px) {
  .container-card {
    display: flex;
    flex-direction: column;
  }

  .card-tutorials{
    max-width: 100%;
    margin-left: 0px !important;
  }

  .image{
    max-width: 100%;
    object-fit: cover;
  }
}

.title-card{
  width: 100%;
  padding: 8px 10px 10px;
  box-shadow: rgb(15 15 15 / 10%) 0px 0px 0px 1px;
  cursor: pointer;
}

.link-tutorials-organization {
  color: #3F4254;
  margin-top: 5px;
}

.link-tutorials-organization:hover {
  color: rgb(92, 101, 192);
}
