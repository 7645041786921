.create-avaliacao-divider {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
}

.create-avaliacao-form {
  width: 100%;
}

.create-questions-container {
  width: 100%;

  padding: 15px;
}

.create-question-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px;

  .create-question-card-title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .question-card-alternatives {
    margin-top: 12px;

    .question-input {
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      padding: 5px;

      label {
        margin-left: 5px;
      }
    }

    .question-correct {
      background-color: #80ed99;
      border-radius: 6px;
    }
  }

  .question-footer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;

    span {
      margin-right: 10px;
      font-size: 14px;
      font-weight: bold;
    }

    svg {
      cursor: pointer;
    }

    svg + svg {
      margin-left: 10px;
    }
  }
}
