@media (max-width: 728px) {
  .ModalComponentContainer {
    width: auto !important;
  }
}

.ModalComponentContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 700px;
  padding: 20px;
  max-height: 92%;
  transform: translate(-50%, -50%);
  background: #f8f8f8;
  border-radius: 10px;
  overflow-y: auto;

  form {
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    textarea {
      margin-top: 10px;
    }
  }
}

::-webkit-scrollbar {
  width: 5px;
  border-radius: 100px;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  background: rgb(180, 179, 179);
}

.modal-admin-button-close {
  background-color: #f53030;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.modal-admin-button-close:hover {
  filter: brightness(0.9);
}

.createCompanyDivForm {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;

  div {
    width: 23% !important;
  }

  & + & {
    margin-top: 10px;
  }
}

.createCompanyDivFormSelects {
  display: flex;
  flex-direction: row;
  margin-top: 10px;
  width: 100%;
  justify-content: space-between;
  align-items: baseline;
  flex-wrap: wrap;

  .css-2b097c-container {
    width: 48%;
  }

  .InputContainer {
    margin-top: 10px !important;
    width: 48% !important;
  }
}

.button-modal {
  height: auto;
  width: 100%;
  padding-bottom: 30px;
}
