.AvatarInput {
  margin-bottom: 32px;
  position: relative;
  align-self: center;
  img {
    width: 186px;
    height: 186px;
    border-radius: 50%;
    object-fit: cover;
  }
  label {
    position: absolute;
    right: 0px;
    bottom: 0px;
    width: 48px;
    height: 48px;
    background: #6f95ff;
    border-radius: 50%;
    border: 0;
    cursor: pointer;
    transition: background 0.2s;
    display: flex;
    justify-content: center;
    align-items: center;
    input {
      display: none;
    }
    svg {
      width: 20px;
      height: 20px;
      color: #fff;
    }
    &:not(:disabled):hover {
      filter: brightness(0.9);
    }
  }
}

.AutoCompleteStyle {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;
}

.TextFieldStyle {
  background: transparent;

  input {
    background: transparent;
    flex: 1;
    border: 0;
    color: #000;
    height: 40px;
    font: 500 1rem Poppins, sans-serif;

    &:focus {
      outline: none;
    }
  }
}
