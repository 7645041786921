@media (max-width: 768px) {
  .admin-company-trail-container {
    margin-top: 40px;
  }

  .admin-trails-card {
    width: 100% !important;
  }
}

@media (min-width: 1023px) and (max-width: 1025px) {
  .admin-trails-card {
    width: 24.1% !important;
    height: 400px !important;
  }

  .admin-trails-card-header {
    flex-direction: column;

    h2 {
      margin-top: 10px;
    }
  }
}

@media (max-width: 425px) {
  .admin-trail-header-search {
    display: none !important;
  }
}


.admin-trail-header {
  width: 100%;
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-left: 15px;
}

.admin-trail-header-end {
  margin-top: -20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
}

.admin-trailheader-divider {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;

  span {
    font-size: 18px;
  }

  svg {
    margin-right: 10px;
  }
}

.admin-trail-header-search {
  margin-left: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  height: 40px;
  border-radius: 10px;
  width: 250px;
  padding: 10px;

  input {
    flex: 1;
    border: none;
    outline: none;
    padding: 10px;
    background-color: transparent;
  }
}

.admin-trails-container {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
  width: 70%;
  height: 100%;
  gap: 10px;

  .admin-trails-card {
    border-radius: 7px;
    width: 24.2%;
    background-color: white;
    height: 350px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px;

    .admin-trails-card-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      img {
        border-radius: 6px;
      }

      h2 {
        font-size: 12.5px !important;
        text-align: center;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;

      }
    }

    .admin-trails-card-descriptions {
      width: 100%;
      margin-top: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }

    .admin-trails-card-footer {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      svg {
        margin-left: 10px;
      }
    }
  }
}

.admin-trail-footer {
  width: 100%;
  margin-top: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
