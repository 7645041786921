* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

:root {
  //Cores Neutras
  -white: #FFFFFF;
  --black: #121214;

  //Error
  --error-light: #f53030;
  --error-dark: #ce3030;

  //Background do Projeto
  --gray-50: #F7F8FA;

  //Cores Texto
  --gray-500: #80808F;

  //Cores - Profissional
  --blue-300: #00B4CC;
  --blue-500: #008C9E;
  --blue-800: #005F6B;

  //Cores - Cluster
  --green-300: #7A9940;
  --green-500: #668036;
  --green-800: #54692E;

  //Cores - Admin
  --red-300: #CC4400;
  --red-500: #A83800;
  --red-800: #802A00;

  //Cores - Empresas
  --purple-300: #6F95FF;
  --purple-500: #5C65C0;
  --purple-800: #413B6B;


}
iframe {
  z-index: -1 !important;
}

// .apexcharts-xaxis {
//   display: none !important;
// }

@media (max-width: 1080px) {
  html {
    font-size: 93.75% // 15px
  }
}

@media (max-width: 720px) {
  html {
    font-size: 87.5% // 14px
  }
}

body {
  //background: var(--black);
  -webkit-font-smoothing: antialiased
}

body, input, textarea, button {
  font: 500 1rem Poppins, sans-serif;
  //color: var(--gray-500);
}

h1, h2, h3, h4, h5, h6, strong {
  font-weight: 600;
  font-family: Poppins, sans-serif;
  //color: var(--gray-800);
}

h1 {
  font-size: 2rem;
}

h2 {
  font-size: 1.5rem;
}

button {
  cursor: pointer;
}
