.SwitchInputOrg {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 15px;
}

.SwitchInputOrg input {
  opacity: 0;
  width: 0;
  height: 0;
}

.sliderRoundOrg {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 34px;
}

.sliderRoundOrg:before {
  position: absolute;
  content: "";
  height: 12px;
  width: 12px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .sliderRoundOrg {
  background-color: #6F95FF;
}

input:focus + .sliderRoundOrg {
  box-shadow: 0 0 1px #6F95FF;
}

input:checked + .sliderRoundOrg:before {
  -webkit-transform: translateX(12px);
  -ms-transform: translateX(12px);
  transform: translateX(12px);
}
