.WhatWillLearnContainer {
  display: grid;
  column-gap: 5px;
  grid-template-columns: 50% 50%;
  width: 100%;
  overflow: hidden;
  gap: 8px;

  padding: 0 0 1.2rem 0;
}
