.TextAreaProfissional {
  background: #f2f3f7;
  width: 100%;
  border: 0;
  border-radius: 10px;
  color: #000;
  height: auto;
  min-height: 80px;
  padding: 20px;

  &::placeholder {
    color: #666360;
  }

  &:focus {
    border: 2px solid #005F6B !important;
    outline: none;
  }
}

.textAreaInputError {
  background: #f2f3f7;
  width: 100%;
  border: 2px solid #f53030 !important;
  border: 0;
  color: #000;
  height: 40px;

  &::placeholder {
    color: #666360;
  }

  &:focus {
    border: 2px solid #005F6B !important;
    outline: none;
  }
}
