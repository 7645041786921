.Container_Playlist {
  width: 100%;
  height: 100%;
}

.DetailsModule {
  display: flex;
  width: 100%;
  -webkit-box-align: center;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: #005f6b;
  padding: 0.5rem 2.5rem;
  border-top-left-radius: 0.42rem;
  border-top-right-radius: 0.42rem;

  span {
    display: block;
    font-size: 17px;
    font-weight: 500;
    color: #fff;
  }

  p {
    font-size: 12px;
    color: #fff;
    margin: 0;
  }
}

.DetailsClass {
  width: 100%;
  height: 380px;
  overflow-y: auto;
}

.class-list {
  margin: 30px;
}

.item-list {
  font-size: 14px;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  z-index: 5;
  margin-bottom: 20px;

  // overflow: hidden;
}

.circle {
  position: relative;
  width: 10px;
  height: 10px;
  border: 0px;
  border-radius: 50%;
  margin-right: 35px;
  flex-shrink: 0;
  z-index: 2;
  transition: box-shadow 0.2s ease 0s;
  background: rgb(225, 225, 230);
}

.name-module {
  display: flex;
  -webkit-box-pack: justify;
  justify-content: space-between;
  width: 100%;
}

.item-list + .item-list::before {
  content: '';
  left: 4px;
  width: 2px;
  top: auto;
  bottom: 50%;
  height: calc(100% + 5px);
  background: #005f6b;
  position: absolute;
}

// .item-list::after {
//   content: '';
//   left: 4px;
//   width: 2px;
//   top: 50%;
//   height: calc(100% + 5px);
//   background: #005f6b;
//   position: absolute;
// }

.WatchClassLabel {
  span {
    font-family: Poppins, sans-serif;
    font-size: 14px;
    cursor: pointer;
    .MuiStepLabel-completed {
      color: rgba(0, 0, 0, 0.54);
    }
  }

  svg {
    color: #008c9e !important;
    cursor: pointer;
    font-size: 22px;
  }
}
