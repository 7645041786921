// .chooseFile {
//   .inputFile {
//     display: none;
//   }

//   label {
//     display: flex;
//     gap: 10px;
//     margin-top: 4px;
//     margin-left: 5px;
//     align-items: center;

//     color: #000;
//     font-size: 1rem;
//     cursor: pointer;

//     &:hover {
//       color: #121214;
//     }
//   }
// }

.corrigido {
  background: #9ea2cf;

  font-size: 1.2rem;
  font-weight: 400;
  color: #fff;

  padding: 0.5rem;
  border-radius: 8px;
  cursor: default;
}
