.forgotPasswordContainer {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100vh;

  align-items: stretch;
  background-color: #fff;
  background-image: url('../../assets/images/wave.svg');
  background-repeat: repeat-x;
  background-attachment: fixed;
  background-position: center bottom -80px;

  p {
    font-size: 0.87rem;
    color: #737380;
    margin-top: 1.25rem;

    a {
      color: #008c9e;
    }
  }

  span {
    font-size: 0.87rem;
    color: #a7a8bb;
    margin-top: 1.5rem;

    text-align: center;

    a {
      font-size: 1rem;
      margin-left: 0.5rem;
      color: #008c9e;
    }
  }
}

.main-forgotpassword-email {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  h1 {
    text-align: center;
    font-size: 2rem;
    color: #2f3044;
  }

  p {
    line-break: auto;
    text-align: center;
    font-size: 13px;
    margin-bottom: 18px;
    padding: 0 30px;
  }

  a {
    color: #008c9e !important;
  }
}

.main-forgotpassword {
  width: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  h2 {
    text-align: center;
    font-size: 1.5rem;
    color: #2f3044;

    margin-bottom: 1.25rem;
  }

  img {
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
  }

  button {
    width: 100%;
  }
}

.forgotPasswordForm {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  max-width: 31rem;


  h1 {
    text-align: center;
    font-size: 2rem;
    color: #2f3044;
  }

  a {
    margin-top: 15px;
    color: #008c9e !important;
  }

  p {
    line-break: auto;
    text-align: center;
    padding: 0 30px;
    font-size: 12px;
    margin-bottom: 18px;
  }

  img {
    height: 70px;
    width: 70px;
    margin-bottom: 20px;
  }
}
