.ConfigPageAdminContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  margin-top: 30px;
  width: 100%;
  padding: 20px;
  border-radius: 6px;
  max-width: 1340px;
}

.ConfigPageAdminHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  width: 100%;
}

.ConfigPageAdminForm {
  width: 100%;
  display: flex;
  margin: 10px 0;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;

  .ConfigInputContainer {
    width: 49% !important;
    margin-bottom: 15px;
  }
}
