.categoryContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: 15px;
  justify-content: flex-start;
  align-items: center;
}

.categoryCard {
  background-color: #005f6b;
  border-radius: 8px;
  padding: 5px;
  margin-right: 10px;

  span {
    color: #fff;
    font-family: Poppins, sans-serif;
  }
}
