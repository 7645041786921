.MaskInputContainer {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #666360;

  & + div {
    margin-top: 8px;
  }


}

.MaskInput {
  background: transparent;
  flex: 1;
  border: 0;
  color: #000;
  height: 40px;
  padding-left: 16px;

  &::placeholder {
    color: #666360;
  }

  &:focus {
    outline: none;
  }
}

.MaskInputContainerClusterActive {
  border: 2px solid #668036 !important;
  outline: none !important;
}

.MaskInputClusterError {
  border: 2px solid #f53030;

  svg {
    color: #f53030;
  }
}

.SearchIconContainer {
  position: relative;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  height: 20px;
  margin-left: 16px;
}

.iconAdminClusterActive {
  color: #668036 !important;
}

.Error {
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #f53030;
    color: #fff;

    &::before {
      border-color: #f53030 transparent;
    }
  }
}
