.create-question-modal-radio-divider {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  align-items: center;
}

.create-question-modal-input-divider {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  & + & {
    margin-top: 10px;
  }

  .InputContainer {
    width: 90%;
  }
}

.AddMoreQuestionDiv {
  width: 100%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8eaec;
  margin-top: 10px;
  border-radius: 6px;
}

.AddMoreQuestionDiv:hover {
  filter: brightness(0.9);
  transition: 0.5s;
  cursor: pointer;
}
