.Container {
  width: 100%;
  height: 100%;

  strong {
    font: 700 1.45rem Poppins, sans;
    color: #005f6b;
  }

  p {
    font: 400 1.1rem Poppins, sans;
    color: #80808f;
  }

  hr {
    height: 3px;
    border-width: 0;
    color: #80808f;
    background-color: #80808f;
  }
}


