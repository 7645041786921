.ProfissionalMaskInputContainer {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #666360;

  & + div {
    margin-top: 8px;
  }


}

.ProfissionalMaskInput {
  background: transparent;
  flex: 1;
  border: 0;
  color: #000;
  height: 40px;
  padding-left: 16px;

  &::placeholder {
    color: #666360;
  }

  &:focus {
    outline: none;
  }
}

.ProfissionalMaskInputContainerActive {
  border: 2px solid #008c9e !important;
  outline: none !important;
}

.ProfissionalMaskInputError {
  border: 2px solid #f53030;

  svg {
    color: #f53030;
  }
}

.iconActive {
  color: #008c9e;
}


