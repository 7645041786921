.CustomButtonComponent {
  height: 40px;
  border-radius: 6px;
  font-weight: 500;
  color: #f8f8f8;
  padding: 0 32px;

  margin-top: 16px;

  display: flex;
  justify-content: center;
  align-items: center;

  cursor: pointer;
  border: 0;

  transition: 0.2s;

  img {
    margin-right: 8px;
  }

  &:not(:disabled):hover {
    filter: brightness(0.9);
  }

  &:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
}
