.TrailDashboardContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.TrailDashboardCardContainer {
  display: flex;
  width: 100%;
  justify-content: space-evenly;
  align-items: center;
}

.TrailDashboardCard {
  background-color: #fff;
  border-radius: 10px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  .TrailDashboardCardContent {
    margin-top: 5px;
    display: flex;
    width: 100%;
    justify-content: flex-start;
    align-items: center;
    gap: 7px;

    span {
      font-size: 16px;
      font-weight: 700px;
    }
  }
}

.TrailDashboardUsername {
  color: rgb(85, 172, 223) !important;
  cursor: pointer;
}
