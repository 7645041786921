.InputContainer {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #666360;

  & + div {
    margin-top: 8px;
  }

  input {
    background: transparent;
    flex: 1;
    border: 0;
    color: #000;
    height: 40px;

    &::placeholder {
      color: #666360;
    }

    &:focus {
      outline: none;
    }
  }

  svg {
    margin-right: 16px;
  }
}

.InputContainerAdminClusterActive {
  border: 2px solid #668036 !important;
  outline: none !important;
}

.InputContainerClusterError {
  border: 2px solid #f53030;

  svg {
    color: #f53030;
  }
}

.iconAdminClusterActive {
  color: #668036 !important;
}

.Error {
  height: 20px;
  margin-left: 16px;

  svg {
    margin: 0;
  }

  span {
    background: #f53030;
    color: #fff;

    &::before {
      border-color: #f53030 transparent;
    }
  }
}
