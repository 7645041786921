.avaliacao-top {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  margin-bottom: 20px;
}

.avaliacao-header {
  width: 100%;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px 15px;

  .avaliacao-header-lines {
    margin-top: 12px;
    display: flex;
    align-items: baseline;
    justify-content: flex-start;

    span {
      margin-left: 5px;
      font-size: 14px;
    }
  }
}

.questions-container {
  width: 100%;
  margin-top: 20px;
  background-color: #fff;
  border-radius: 6px;
  padding: 15px 15px;

  .create-question-card-title {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
  }

  .question-card {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;

    span {
      font-weight: bold;
      font-size: 15px;
    }

    .question-card-alternatives {
      margin-top: 12px;

      .question-input {
        display: flex;
        justify-content: flex-start;
        align-items: baseline;
        padding: 5px;

        label {
          margin-left: 5px;
        }
      }

      .question-correct {
        background-color: #80ed99;
        border-radius: 6px;
      }
    }
  }
}

.question-card-divider {
  width: 100%;
  background-color: #383838;
  height: 1px;
  margin: 20px 0;
}

// .question-card {
//   & + & {
//     margin-top: 20px;
//   }
// }
