.deleteModal {
  position: absolute;
  top: 50%;
  left: 50%;
  width: auto;
  padding: 40px;
  transform: translate(-50%, -50%);
  background: #f8f8f8;
  border-radius: 10px;
}

.modal-admin-button-close {
  background-color: red;
  position: absolute;
  top: 0;
  right: 0;
  width: 40px;
  height: 40px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
}

.modal-admin-button-close:hover {
  filter: brightness(0.9);
}

.react-select-container {
  background: #f2f3f7;
  border-radius: 10px;
  padding: 16px;
  width: 100%;
  display: flex;
  align-items: center;

  color: #666360;
}

.css-2b097c-container {
  background: #f2f3f7;
  border-radius: 10px;
  width: 100%;
  display: flex;
}

.react-select__control {
  width: 100% !important;
  background-color: transparent !important;
}
